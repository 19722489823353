<template>
  <LazyZBadge
    v-if="config.label && config.icon"
    variant="light"
    :class="config.label"
  >
    <Fa
      :icon="['fas', config.icon]"
      class="mr-1"
    />
    {{ t(config.label) }}
  </LazyZBadge>
</template>

<script setup lang="ts">
import { RvBadges } from '@/lib/enums'

const props = defineProps<{
  badge: string
}>()

const { t } = useI18n()

const config = computed(() => {
  let label, icon

  switch (props.badge) {
    case RvBadges.Superhost:
      label = 'superhost'
      icon = 'award-simple'
      break

    case RvBadges.InstantBook:
      label = 'instantbook'
      icon = 'bolt'
      break

    case RvBadges.DeliveryOnly:
      label = 'deliveryonly'
      icon = 'truck-fast'
      break

    default:
      break
  }

  return { label, icon }
})
</script>

<style lang="scss" scoped>
.zbadge {
  &.superhost,
  &.instantbook,
  &.deliveryonly {
    border-radius: 1rem;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 2;
    font-size: 0.75rem;
    padding: 0.1875rem 0.625rem;
    svg {
      font-size: 0.875rem;
      vertical-align: -0.1875rem;
    }
  }

  &.instantbook,
  &.deliveryonly {
    color: getColor('primary-500');
  }

  &.superhost {
    color: getColor('highlight-500');
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "superhost": "Superhost",
    "instantbook": "Instant Book",
    "deliveryonly": "Delivery Only"
  },
  "fr": {
    "superhost": "Superhôtes",
    "instantbook": "Réservation Instantanée",
    "deliveryonly": "Livraison seulement"
  }
}
</i18n>
